<!-- https://github.com/intlify/vue-i18n/blob/master/examples/type-safe/type-annotation/src/components/en-US.json -->
<i18n lang="json">
{
  "en": {
    "unplugin-hello": "Hello, unplugin-vue-i18n!"
  },
  "zh": {
    "unplugin-hello": "你好，unplugin-vue-i18n！"
  }
}
</i18n>

<template>
  <div class="i18nComp">
    <div><span class="label">$i18n.availableLocales</span>: {{ $i18n.availableLocales }}</div>
    <p><span class="label">$t('message.hello')</span>: {{ $t('message.hello') }}</p>

    <p>
      <span class="label">t('unplugin-hello')</span>: {{ t('unplugin-hello') }}
      <b>这个定义在i18n lang="json"的</b>
    </p>
    <p>
      <span class="label">t('message.greeting')</span>: {{ t('message.greeting') }}
      <b>这个在i18n lang="json"没有，会fallback到全局的</b>
    </p>

    <form>
      <label for="locale">Change locale:</label>
      <select id="locale" v-model="$i18n.locale">
        <option v-for="locale in $i18n.availableLocales" :key="locale" :value="locale">{{ locale }}</option>
      </select>
      <p>$i18n.locale: {{ $i18n.locale }}</p>
    </form>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  inheritLocale: true,
  useScope: 'local',
});
</script>

<style>
.i18nComp .label,
.i18nComp label {
  color: #2c3e50;
  font-weight: bold;
  background-color: #f8f9fa;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
}
</style>
